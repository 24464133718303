// src/pages/SearchResults.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToCart } from './../redux/actions/cartActions';

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('name');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [quantity, setQuantity] = useState(1);
  const [isAdded, setIsAdded] = useState(null);  // Track if item is added to the cart
  const dispatch = useDispatch();



  const handleAddToCart = (productId, quantity) => {
    dispatch(addToCart(productId, quantity));
    setIsAdded(productId);  // Set the product ID to indicate it's added
    setTimeout(() => setIsAdded(null), 2000);  // Reset after 2 seconds
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/products/search?name=${query}`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false);
      }
    };
    if (query) fetchProducts();
  }, [query]);

  if (loading) return <p>Loading...</p>;

  return (



    <div>

      {products.length ? (
        <section
          id="Projects"
          className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
        >
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-8 lg:max-w-7xl mx-auto">
            {products.map((product) => (
              <Link to={`/products/viewproduct/${product?._id}`}>
                <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md">
                  <div className="relative">
                    <img className="w-full rounded-t-lg" src={product.images[0]} alt={product.title} />
                    {
                      product.sale ? (<div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">Sale </div>) : (<div></div>)
                    }
                    {/* <div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                     {product.sale ? "Sale" : ""}
                   </div> */}
                  </div>
                  <div className="p-4">
                    {/* <div className="flex items-center mb-2">
                 <span className="text-yellow-400 text-sm mr-2"></span>
                 <span className="text-sm text-gray-500">({product.rating})</span>
               </div> */}
                    <h5 className="text-lg font-bold text-gray-900">{product.name}</h5>
                    <div className="flex items-center mt-2">
                      <span className=" font-bold text-lg" style={{ color: "#25BCCC" }}>&#8377; {product.price}</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </section>
      ) : (
        <p>No products found.</p>
      )}
    </div>
  );
};

export default SearchResults;
