import React from 'react';
import { useNavigate } from 'react-router-dom';

const ImageGallery = () => {
  const navigate = useNavigate();
  const ageGroups = [
    { color: '#F2B359', caption: '0-1 year', ageGroup: '0-1' },
    { color: '#25BCCC', caption: '1-2 years', ageGroup: '1-2' },
    { color: '#E8581A', caption: '2-5 years', ageGroup: '2-5' },
    { color: '#CDDC6F', caption: '6+ years', ageGroup: '5+' },
  ];

  return (
    <div className="container mx-auto px-4 my-16 relative bg-gradient-to-r from-blue-200 to-purple-200 py-10">
      <h2 className="text-2xl sm:text-2xl lg:text-4xl text-gray-800 text-center mb-14">Buy product age group</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {ageGroups.map((item, index) => (
          <div 
            key={index} 
            className="text-center cursor-pointer" 
            onClick={() => navigate(`/product/by-age/${item.ageGroup}`)}
          >
            <div 
              className="w-40 h-40 mx-auto mb-4 flex items-center justify-center rounded-full text-white text-lg font-bold shadow-lg transform transition-transform hover:scale-110"
              style={{ backgroundColor: item.color }}
            >
              {item.caption}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
