import api, { setAuthToken } from './../../service/api';
import swal from 'sweetalert';

// Action Types
export const SIGNUP = 'SIGNUP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const SIGNIN = 'SIGNIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const VERIFY_OTP_PASSWORD_RESET = 'VERIFY_OTP_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_PROFILE = 'GET_PROFILE';
export const LOGOUT = 'LOGOUT';

// Action Creators
export const signup = (userData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/signup', userData);
    if (response.status === 201) {
      dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
      swal("Success", "Signup successful! Please check your email for verification.", "success");
      return response; // Return the response so it can be used in the component
    }
  } catch (error) {
    dispatch({ type: 'SIGNUP_FAILURE', payload: error.message });
    swal("Error", "Signup failed. Please try again.", "error");
    throw error;
  }
};

export const verifyOtp = (otpData, navigate) => async (dispatch) => {
  try {
    const response = await api.post('/auth/verify-otp', otpData);
    dispatch({ type: VERIFY_OTP, payload: response.data });
    swal("Success", "OTP verified successfully!", "success").then(() => {
      navigate('/signin');  // Redirect to sign-in page after verification
    });
  } catch (error) {
    swal("Error", "OTP verification failed. Please check your OTP and try again.", "error");
    console.error('OTP verification failed', error);
  }
};

export const signin = (loginData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/signin', loginData);
    const token = response.data.token;
    if (token) {
      localStorage.setItem('token', token);  // Store token in localStorage
      setAuthToken(token);  // Set token to headers
      dispatch({ type: SIGNIN, payload: response.data });
      swal("Success", "Signin successful!", "success").then(() => {
        window.location.href = "/";
      });
    } else {
      swal("Error", "Signin failed. Token not received.", "error");
      console.error('Token not received');
    }
  } catch (error) {
    swal("Error", "Signin failed. Please check your credentials and try again.", "error");
    console.error('Signin failed', error);
  }
};

export const forgotPassword = (emailData, navigate) => async (dispatch) => {
  try {
    const response = await api.post('/auth/forgot-password', emailData);
    if(response.status === 201) {
      dispatch({ type: FORGOT_PASSWORD, payload: response.data });
      swal("Success", "Password reset link has been sent to your email.", "success").then(() => {
        navigate('/verify-otp-password-reset', { state: { email: emailData.email } });
      });
      return response; // Return the response so it can be used in the component
    }
  } catch (error) {
    swal("Error", "Failed to send password reset link. Please try again.", "error");
    console.error('Forgot password failed', error);
  }
};

export const verifyOtpPasswordReset = (otpData,navigate) => async (dispatch) => {
  try {
    const response = await api.post('/auth/verify-otp-password-reset', otpData);
   if(response.status === 201){
    dispatch({ type: VERIFY_OTP_PASSWORD_RESET, payload: response.data });
    swal("Success", "OTP verified successfully!", "success").then(() => {
      navigate('/reset-password', { state: { email: otpData.email } });
    });
    return response
  }
  } catch (error) {
    swal("Error", "OTP verification for password reset failed. Please try again.", "error");
    console.error('OTP verification for password reset failed', error);
  }
};

export const resetPassword = (resetData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/reset-password', resetData);
    if(response.status === 201){
      dispatch({ type: RESET_PASSWORD, payload: response.data });
      swal("Success", "Password reset successfully!", "success").then(() => {
        window.location.href = "/signin";
      });
    }
  } catch (error) {
    swal("Error", "Failed to reset password. Please try again.", "error");
    console.error('Reset password failed', error);
  }
};

export const getProfile = () => async (dispatch) => {
  const token = localStorage.getItem('token');  
  
  if (token) {
    setAuthToken(token);  
  } else {
    console.error('No token found');
    return;  // Stop if token is missing
  }

  try {
    const response = await api.get('/user/profile/me');
    dispatch({ type: GET_PROFILE, payload: response.data });
  } catch (error) {
    swal("Error", "Failed to retrieve profile data. Please try again.", "error");
    console.error('Get profile failed', error.response ? error.response.data : error);
  }
};
