import api from './../../service/api';

// Action Types
export const ADD_TO_CART = 'ADD_TO_CART';
export const VIEW_CART = 'VIEW_CART';

// Add product to the cart

export const addToCart = (productId, quantity) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return { status: 401 };
    }

    const response = await api.post(
      `/cart/add`,
      { productId, quantity },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: 'ADD_TO_CART_SUCCESS',
      payload: response.data,
    });

    return { status: 200 }; // Success status
  } catch (error) {
    console.error('Add to Cart Error:', error);
    return { status: 500 }; // Internal server error status
  }
};


// View the cart
export const viewCart = () => async (dispatch) => {
  try {
    // Get token from localStorage
    const token = localStorage.getItem('token');

    // Set Authorization header if token exists
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    const { data } = await api.get('/cart/viewcart', config); // Modify this endpoint as needed
    console.log(data)
    // Dispatch the action with the cart data
    dispatch({
      type: VIEW_CART,
      payload: data,
    });
  } catch (error) {
    console.error("Error fetching cart data:", error);
    throw error; // or dispatch an error action if you want to handle this globally
  }
};
