import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createOrder, verifyPayment } from '../../redux/actions/checkoutActions';

const CheckoutForm = () => {
    const location = useLocation();
    const { cartItems, totalAmount } = location.state || { cartItems: [], totalAmount: 0 };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        // Check if cart items are present or required parameters are included
        const cartId = searchParams.get('cartId');
        const autoRedirect = searchParams.get('auto_redirect');

        if (!cartItems.length && (!cartId || autoRedirect !== 'false')) {
            navigate('/viewcart'); // Redirect to the cart page
        }
    }, [cartItems, searchParams, navigate]);

    const [formData, setFormData] = useState({
        shipping: { name: '', email: '', phone: '', address: '', city: '', state: '', postalCode: '' },
        paymentMethod: 'Cash on Delivery',
    });
    const [calculateAmount, setCalculateAmount] = useState(Number(totalAmount) + 199);



    const shippingAmount = 0;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, shipping: { ...formData.shipping, [name]: value } });
    };
    console.log(cartItems)

    useEffect(() => {
        setCalculateAmount(Number(totalAmount) + shippingAmount);
    }, [totalAmount]);

    const handleSubmit = async (e) => {
        try {
            const orderData = {
                ...formData,
                cartItems: cartItems.map((item) => ({
                    product: item.productId, // or just productId if you prefer that field name
                    quantity: item.quantity,
                })),
                totalAmount: calculateAmount,
            };
            // Dispatch createOrder to backend and get checkout response
            const order = await dispatch(createOrder(orderData, navigate));

            if (formData.paymentMethod === 'Pay Online - Razorpay') {
                const options = {
                    key: 'rzp_test_cPpppuAG65bZJA',
                    amount: calculateAmount * 100,
                    currency: 'INR',
                    name: 'Pehell',
                    description: 'Thank you for your purchase',
                    order_id: order.razorpayOrderId, // Using Razorpay order ID
                    handler: async (response) => {
                        const paymentData = {
                            razorpayOrderId: response.razorpay_order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpaySignature: response.razorpay_signature,
                            checkoutId: order._id, // Using checkout ID
                        };
                        await dispatch(verifyPayment(paymentData, navigate)); // Verifying payment
                    },
                    prefill: {
                        name: formData.shipping.name,
                        email: formData.shipping.email,
                        contact: formData.shipping.phone,
                    },
                    theme: { color: '#3399cc' },
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
            }
        } catch (error) {
            console.error('Payment Error:', error);
        }
    }


    return (
        <div className="max-w-6xl mx-auto py-12 px-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <p className="text-2xl font-semibold mb-4">Order Summary</p>
                    <p className="text-gray-600 mb-6">Review your items and select your shipping method.</p>
                    {cartItems.map((item, index) => (
                        <div key={index} className="flex mb-4">
                            <img className="w-20 h-20 object-cover rounded-md mr-4" src={item.productId.images[0]} alt={item.productId.name} />
                            <div>
                                <span className="font-medium">{item.productId.name}</span>
                                <span className="text-gray-500 ms-24">Qty: {item.quantity}</span>
                                <p className="text-lg font-bold">&#8377; {item.productId.price}</p>
                            </div>
                        </div>
                    ))}
                    <hr className="my-4" />
                    <div className="flex justify-between mb-4"><span className="font-semibold">Subtotal</span><span className="font-semibold">&#8377; {totalAmount}</span></div>
                    <div className="flex justify-between mb-4"><span className="font-semibold">Shipping</span><span className="font-semibold">&#8377; {shippingAmount}</span></div>
                    <div className="flex justify-between mb-6"><span className="font-semibold text-xl">Total</span><span className="font-semibold text-xl">&#8377; {calculateAmount}</span></div>
                    <div className="flex justify-between items-center mb-6">
                        <input
                            type="text"
                            placeholder="Enter Coupon Code"
                            className="w-full p-3 border rounded-lg"
                        />
                        <button className="px-6 py-3 bg-indigo-600 text-white rounded-lg">Apply</button>
                    </div>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <p className="text-2xl font-semibold mb-4">Shipping Details</p>
                    <div className="space-y-4">
                        <input type="text" name="name" placeholder="Full Name" className="w-full p-3 border rounded-lg" value={formData.shipping.name} onChange={handleInputChange} required />
                        <input type="email" name="email" placeholder="Email Address" className="w-full p-3 border rounded-lg" value={formData.shipping.email} onChange={handleInputChange} required />
                        <input type="text" name="phone" placeholder="Phone Number" className="w-full p-3 border rounded-lg" value={formData.shipping.phone} onChange={handleInputChange} required />
                        <input type="text" name="address" placeholder="Shipping Address" className="w-full p-3 border rounded-lg" value={formData.shipping.address} onChange={handleInputChange} required />
                        <div className="grid grid-cols-2 gap-4">
                            <input type="text" name="city" placeholder="City" className="w-full p-3 border rounded-lg" value={formData.shipping.city} onChange={handleInputChange} required />
                            <input type="text" name="state" placeholder="State" className="w-full p-3 border rounded-lg" value={formData.shipping.state} onChange={handleInputChange} required />
                        </div>
                        <input type="text" name="postalCode" placeholder="Postal Code" className="w-full p-3 border rounded-lg" value={formData.shipping.postalCode} onChange={handleInputChange} required />
                        <div className="mt-6">
                            <label className="block text-lg font-medium">Payment Method</label>
                            <div className="flex items-center space-x-6 mt-2">
                                <input type="radio" id="cod" name="paymentMethod" value="Cash on Delivery" checked={formData.paymentMethod === 'Cash on Delivery'} onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })} aria-selected />
                                <label htmlFor="cod">Cash on Delivery</label>
                                <input type="radio" id="razorpay" name="paymentMethod" value="Pay Online - Razorpay" checked={formData.paymentMethod === 'Pay Online - Razorpay'} onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })} disabled />
                                <label htmlFor="razorpay">Pay Online (Razorpay) (Develop mode )</label>
                            </div>
                        </div>
                        <button onClick={handleSubmit} className="w-full py-3 mt-6 bg-indigo-600 text-white rounded-lg">{formData.paymentMethod === 'Cash on Delivery' ? 'Place Order' : 'Proceed to Payment'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutForm;
