import { ADD_TO_WISHLIST } from "../actions/wishListActions";

const initialState = {
  wishListItem: [],
};

const wishListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      return { ...state, wishListItem: [...state.wishListItem, action.payload.item] };
      
    default:
      return state;
  }
};

export default wishListReducer;