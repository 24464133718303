import api from './../../service/api';

// Action Types
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const VIEW_CART = 'VIEW_CART';

// Add product to the wishlist
export const addToWishList = (productId, quantity) => async (dispatch) => {
  try {
    // Check token  
    const token = localStorage.getItem('token');
    if (!token) {
      return { status: 401 }; // Return unauthorized status
    }
    const userId = token ? JSON.parse(atob(token.split('.')[1])).userId : null;

    // Set Authorization header if token exists
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    // Send the product ID, quantity, and userId to the backend
    const response = await api.post('/wishlist/add', { productId, quantity, user: userId }, config);

    // Dispatch the action to add the item to the wishlist
    dispatch({
      type: ADD_TO_WISHLIST,
      payload: {
        item: response.data,
      },
    });
    return { status: 200 };
  } catch (error) {
    console.error('Error adding to wishlist:', error);
  }
};