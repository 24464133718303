import React, { useEffect, useState } from 'react';
import api from '../../service/api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  // Fetch Cart Data
  const fetchCart = async () => {
    const token = localStorage.getItem('token');
    try {
      const res = await api.get(`/cart/viewcart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCart(res.data.cart?.products || []); // Ensure safe access to cart data
    } catch (error) {
      console.error('Failed to fetch cart:', error);
    }
  };

  useEffect(() => {
    fetchCart(); // Fetch cart on mount
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await api.delete(`/cart/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Swal.fire('Success', 'Product removed from cart', 'success');
      fetchCart(); // Refresh the cart after deletion
    } catch (error) {
      console.error('Error removing product from cart:', error);
      Swal.fire('Error', 'Failed to remove product from cart', 'error');
    }
  };

  // Calculate Total
  const total = cart.length
    ? cart.reduce((acc, item) => acc + item.productId.price * item.quantity, 0).toFixed(2)
    : '0.00';

  // Handle Checkout
  const handleCheckout = () => {
    if (cart.length > 0) {
      // Ensure cartId is safely retrieved from the first cart item
      const cartId = cart[0]?._id; // Using optional chaining for safe access

      if (cartId) {
        navigate(`/checkout?cartId=${cartId}&auto_redirect=false`, {
          state: { cartItems: cart, totalAmount: total },
        });
      }
    }
  };



  return (
    <div className="p-4 max-w-2xl mx-auto mt-16">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Cart Header */}
        <div className="flex items-center justify-between px-4 py-3 bg-gray-200">
          <h1 className="text-lg font-bold">Shopping Cart</h1>
          <span className="text-gray-600">({cart.length} items)</span>
        </div>

        {/* Cart Items */}
        <div className="p-4">
          {cart.length > 0 ? (
            cart.map((item) => (
              <div
                key={item._id}
                className="flex items-center justify-between border-b pb-3 mb-3"
              >
                {/* Product Image */}
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 object-contain rounded-lg mr-4"
                    src={item.productId.images[0]}
                    alt={item.productId.name}
                  />
                  {/* Product Info */}
                  <div>
                    <h2 className="text-lg font-bold">{item.productId.name}</h2>
                    <span className="text-gray-600">&#8377;{item.productId.price}</span>
                  </div>
                </div>

                {/* Quantity & Delete */}
                <div className="flex items-center space-x-6">
                  <span className="text-gray-600">Qty: {item.quantity}</span>
                  <i
                    className="bi bi-trash-fill text-red-800 text-xl cursor-pointer hover:text-red-600"
                    onClick={() => handleDelete(item.productId._id)}
                  ></i>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center">Your cart is empty.</p>
          )}
        </div>

        {/* Cart Footer */}
        <div className="px-4 py-3 bg-gray-200">
          <div className="flex justify-between items-center">
            <span className="font-bold text-lg">Total:</span>
            <span className="font-bold text-lg">&#8377;{total}</span>
          </div>
          <button
            onClick={handleCheckout}
            className="block w-full mt-4 text-white font-bold py-2 px-4 rounded"
            style={{ background: '#25bccc' }}
            disabled={cart.length === 0}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
