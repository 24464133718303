import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from './../../redux/actions/authActions';

const Profile = () => {
  const dispatch = useDispatch();
  
  // Getting profile data from Redux store
  const profile = useSelector((state) => state.auth.profile);
  const loading = useSelector((state) => state.auth.loading); // If you have a loading state

  useEffect(() => {
    dispatch(getProfile()); // Dispatch action to fetch profile data
  }, [dispatch]);

  // If profile is still loading, display a loading message
  if (loading) {
    return <p>Loading profile...</p>;
  }

  // If profile is not available, show an error message
  if (!profile) {
    return <p>No profile data available</p>;
  }

  return (
    <div>
      <h2>Profile</h2>
       
              <p><strong>Name:</strong> {profile.profile.name}</p>
              <p><strong>Email:</strong> {profile.profile.email}</p>
              <p><strong>Phone:</strong> {profile.profile.phone}</p>
              {/* <p><strong>Role:</strong> {profile.profile.role}</p> */}
            
        
      {/* Add more fields as necessary */}
    </div>
  );
};

export default Profile;
