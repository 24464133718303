// src/components/ForgotPasswordForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from './../../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    try{
     const response = dispatch(forgotPassword({ email },navigate));
     if (response.status === 201) {
      navigate('/verify-otp-password-reset', { state: { email } });
    }
    }catch(err){
      console.log(err);
    }
  };

  return (
    <div className="flex h-screen ">
      <div className="w-full max-w-xs m-auto  rounded p-5" style={{ background: "#EFF6FF" }}>

        <form onSubmit={handleSubmit}>
          
            <div>
            <label className="block mb-2 text-[#25BCCC]">E-Mail</label>
              <input
                className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300" type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
         

          <div>
            <button
              className="w-full bg-[#25BCCC]  text-white font-bold py-2 px-4 mb-6 rounded"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
