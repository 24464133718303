import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import api from "../../service/api";
import Swal from "sweetalert2";
import { addToCart } from "../../redux/actions/cartActions";

const Wishlist = () => {
    const [wishlist, setWishlist] = useState(null);
    const dispatch = useDispatch();

    const fetchData = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await api.get("/wishlist/view", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setWishlist(response.data.wishList);
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleAddToCart = (productId, quantity) => {
        if (productId && quantity) {
            dispatch(addToCart(productId, quantity));
            Swal.fire("Success", "Product added to cart", "success");
        }
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem("token");
        try {
            await api.delete(`/wishlist/remove/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            Swal.fire("Success", "Product removed from wishlist", "success");
            fetchData();
        } catch (error) {
            console.error("Error removing product from wishlist:", error);
            Swal.fire("Error", "Failed to remove product from wishlist", "error");
        }
    }

    return (
        <div>
            <div className="p-4 max-w-xl mx-auto mt-16">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="flex items-center justify-between px-4 py-3 bg-gray-200">
                        <h1 className="text-lg font-bold">Wishlist</h1>
                    </div>
                    <div className="p-4">
                        {wishlist && wishlist.products.length > 0 ? (
                            wishlist.products.map(item => (
                                <div key={item._id} className="flex items-center mb-4">
                                    <img
                                        className="h-16 w-16 object-contain rounded-lg mr-4"
                                        src={item.productId.images[0]}
                                        alt={item.productId.name}
                                    />
                                    <div className="flex-1">
                                        <h2 className="text-lg font-bold">{item.productId.name}</h2>
                                        <span className="text-gray-600">&#8377;{item.productId.price}</span>
                                    </div>
                                    <div className="text-gray-600">
                                        <i className="bi bi-cart-plus-fill text-[#25BCCC] text-xl cursor-pointer" 
                                         onClick={() => handleAddToCart(item.productId._id, item.quantity)}></i>

                                        <i class="bi bi-trash-fill text-red-800 text-xl cursor-pointer ms-4" 
                                         onClick={() => handleDelete(item.productId._id)}></i>
                                       
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-600">Your Wishlist is empty.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wishlist;