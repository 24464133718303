import React from "react";

const BabyBanner = () => {
  return (
    <div
      className="relative bg-gray-100 py-16 px-8 lg:px-20 flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url('https://img.freepik.com/free-psd/maternity-baby-shower-background_23-2150237229.jpg?t=st=1738345906~exp=1738349506~hmac=bdb2ca5072d2d913f342788901b22e0518c84df0148c06fdd23d9d1a5fc59ee3&w=900')`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 -z-10" />

      {/* Content section */}
      <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-16 max-w-6xl">
        {/* Text content */}
        <div className="text-center lg:text-left max-w-lg space-y-6">
          <h1 className="text-2xl sm:text-2xl lg:text-4xl text-white font-bold">
            Designed for Tiny hands, built for big dreams
          </h1>
          <button className="text-white py-3 px-6 rounded-lg text-lg" style={{ background: "#25BCCC" }}>
            Shop Now
          </button>
        </div>

        {/* Image content */}
        <div className="relative">
          {/* Baby image */}
          <div className="w-72 h-72 lg:w-96 lg:h-96 relative overflow-hidden rounded-lg shadow-lg">
            <img
              src="https://img.freepik.com/free-photo/smiling-mom-with-cute-baby-boy-outdoors_624325-4194.jpg?ga=GA1.1.571894306.1703328841&semt=ais_hybrid"
              alt="www.pehell.com"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Decorations */}
          <div className="absolute top-8 left-8 w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center">
            🧸
          </div>
          <div className="absolute bottom-8 left-16 w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
            🍼
          </div>
          <div className="absolute bottom-12 right-8 w-8 h-8 bg-pink-100 rounded-full flex items-center justify-center">
            ⭐
          </div>
        </div>
      </div>
    </div>
  );
};

export default BabyBanner;
