import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './../assets/logo.jpg';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem('token');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = "/";
  };

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const closeDropdown = (e) => {
      if (isDropdownOpen && !e.target.closest('.dropdown')) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, [isDropdownOpen]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/search?name=${searchQuery}`);
    }
  };

  return (
    <>
      {/* Top component displaying welcome message and social icons */}
      <div
        style={{
          backgroundColor: '#25bccc',
          color: '#fff',
          display: 'flex',
          textAlign: "center",
          justifyContent: "center",
          padding: '10px 0',
        }}
      >
        <p>Welcome To Pehell Family | Free Shipping Pan India</p>
      </div>

      <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xxl lg:px-14 sm:px-8 mx-auto">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              className="h-6 mr-3 sm:h-9"
              alt="Pehell Logo"
            />
          </Link>

          <div className="flex items-center lg:order-2">
            {/* Search Icon and Input */}
            {isSearchActive ? (
              <form onSubmit={handleSearchSubmit} className="flex items-center">
                <input
                  type="text"
                  placeholder="Search products..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="p-2 border rounded-md"
                />
                <button type="button" onClick={() => setIsSearchActive(false)} className="ml-2 text-gray-600" style={{ fontSize: "20px" }}>
                  <i className="bi bi-x"></i>
                </button>
              </form>
            ) : (
              <i className="bi bi-search cursor-pointer " onClick={() => setIsSearchActive(true)} style={{ fontSize: "20px" }}></i>
            )}

            {/* Show Cart and User icons only if search is not active */}
            {!isSearchActive && (
              <>
                <Link to="/viewcart">
                  <i className="bi bi-cart2 mx-4" style={{ fontSize: "20px" }}></i>
                </Link>

                {isAuth ? (
                  <div className="relative">
                    <i
                      className="bi bi-person text-red-800 cursor-pointer dropdown"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      style={{ fontSize: "20px" }}
                    ></i>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-50">
                        <ul className="text-gray-700">
                          <li><Link to="/profile" className="block px-4 py-2 hover:bg-gray-100">Profile</Link></li>
                          <li><Link to="/order-history" className="block px-4 py-2 hover:bg-gray-100">Orders</Link></li>
                          <li><Link to="/wishlist" className="block px-4 py-2 hover:bg-gray-100">Wishlist</Link></li>
                          <li><button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100">Logout</button></li>
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to="/signin">
                    <i className="bi bi-person text-gray-500 text-2xl"></i>
                  </Link>
                )}
              </>
            )}

            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMobileMenuOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          {/* Mobile Menu */}
          <div className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu-2">
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {['/', '/product', '/product-new', '/about', '/contact'].map((path, index) => (
                <li key={index}>
                  <Link 
                    to={path} 
                    className={`block py-2 pl-3 pr-4 ${isActive(path) ? 'text-[#25bccc]' : 'text-gray-700'} hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:p-0`} 
                    style={{ fontSize: "20px" }} 
                    onClick={() => setIsMobileMenuOpen(false)} // Close menu on click
                  >
                    {path === '/' ? 'Home' : path.slice(1).replace('-', ' ')}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
