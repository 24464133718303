import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 text-black">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        At Pehell, we are dedicated to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you interact with our brand, including our website, products, and services.
      </p>
      <h2 className="text-xl font-bold mb-2">1. Information We Collect:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>Personal Information: When you make a purchase or sign up for our newsletter, we may collect personal information such as your name, email address, shipping address, and phone number.</li>
        <li>Payment Information: If you make a purchase, we collect payment information such as credit/debit card details or other payment methods.</li>
        <li>Non-Personal Information: We may also collect non-personal information such as browsing activity, device information, and demographic data to improve our services.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">2. How We Use Your Information:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>Your personal information is used to process orders, communicate with you about your purchase, and provide customer support.</li>
        <li>We may use non-personal information for analytics, marketing, and improving our products and services.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">3. Data Security:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>We implement industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</li>
        <li>Your payment information is encrypted using secure socket layer technology (SSL) to ensure secure transactions.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">4. Data Sharing:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>We do not sell, trade, or rent your personal information to third parties without your consent.</li>
        <li>Your information may be shared with trusted third-party service providers who assist us in operating our website, processing payments, and delivering products.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">5. Children&apos;s Privacy:</h2>
      <p className="mb-4">
        Our products are designed for children, and we are committed to protecting their privacy. We do not knowingly collect personal information from children under the age of 13 without parental consent.
      </p>
      <h2 className="text-xl font-bold mb-2">6. Updates to Privacy Policy:</h2>
      <p className="mb-4">
        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on our website, and your continued use of our services signifies your acceptance of the updated policy.
      </p>
      <p>
        By using our website or purchasing our products, you consent to the terms outlined in this Privacy Policy. If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:contact@pehell.com">contact@pehell.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
