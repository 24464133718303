// src/components/ResetPasswordForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword } from './../../redux/actions/authActions';
import { useLocation } from 'react-router-dom';

const ResetPasswordForm = () => {
  const location = useLocation();
  const { email } = location.state || {};  // Retrieve email from state
  const [newPassword, setNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ email, newPassword }));
  };

  return (
    <div className="flex h-screen ">
      <div className="w-full max-w-xs m-auto  rounded p-5" style={{ background: "#25BCCC" }}>

        <form onSubmit={handleSubmit}>
          {email && (
            <div>
              <label className="block mb-2 text-[#25BCCC]">Opt send to this mail</label>
              <input
                className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300" type="text"
                value={email}
                readOnly
              />
            </div>
          )}
           <div>
            <label className="block mb-2 text-[#25BCCC]">New Password</label>
            <div className="relative w-full mb-6">
              <input
                className="w-full p-2 border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"
              >
                {showPassword ? <i class="bi bi-eye-slash-fill"></i> : <i class="bi bi-eye-fill"></i>}
              </button>
            </div>

          </div>
          <div>
            <button
              className="w-full bg-[#25BCCC]  text-white font-bold py-2 px-4 mb-6 rounded"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
