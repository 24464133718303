import { combineReducers } from 'redux';
import authReducer from './authReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import { orderReducer } from './checkoutReducer';
import wishListReducer from './wishListReducer';


export default combineReducers({
  auth: authReducer,
  products : productReducer,
  cart: cartReducer,
  checkout : orderReducer,
  wishList: wishListReducer
});
