// src/components/VerifyOtpPasswordResetForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { verifyOtpPasswordReset } from './../../redux/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyOtpPasswordResetForm = () => {
  const location = useLocation();
  const { email } = location.state || {};  // Retrieve email from state
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();  // Add navigate hook
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyOtpPasswordReset({ email, otp },navigate));
  };

  return (
    <div className="flex h-screen ">
      <div className="w-full max-w-xs m-auto  rounded p-5" style={{background: "#25BCCC"}}>
        
        <form onSubmit={handleSubmit}>
          {email && (
            <div>
               <label className="block mb-2 text-[#25BCCC]">Opt send to this mail</label>
              <input
    className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"                type="text"
                value={email}
                readOnly
              />
            </div>
          )}
          <div>
            <label className="block mb-2 text-indigo-500" htmlFor="otp">OTP</label>
            <input
  className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"              type="text"
              name="otp"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div>
            <button
              className="w-full bg-[#25BCCC]  text-white font-bold py-2 px-4 mb-6 rounded"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtpPasswordResetForm;
