// src/components/SigninForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signin } from './../../redux/actions/authActions';
import { Link } from 'react-router-dom';

const SigninForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signin({ email, password }));
  };

  return (
    <div className="flex h-screen ">
      <div className="w-full max-w-xl m-auto  rounded p-5" style={{ background: "#EFF6FF" }}>
        <header>

        </header>
        <form onSubmit={handleSubmit} className=''>
          <div>
            <label className="block mb-2 text-[#25BCCC]">E-Mail</label>
            <input
              className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300" type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <label className="block mb-2 text-[#25BCCC]">Password</label>
            <div className="relative w-full mb-6">
              <input
                className="w-full p-2 border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"
              >
                {showPassword ? <i class="bi bi-eye-slash-fill"></i> : <i class="bi bi-eye-fill"></i>}
              </button>
            </div>

          </div>

          <div className='float-right mb-6'>
            <Link
              className="text-[#25BCCC] hover:text-pink-700 text-lg  "
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </div>
          <div>
            <button
              className="w-full bg-[#25BCCC]  text-white font-bold py-2 px-4 mb-6 rounded"
              type="submit"
            >
              Signin
            </button>
          </div>
        </form>
        <footer>

          <Link
            className="text-[#25BCCC] hover:text-pink-700 text-lg float-right"
            to="/signup"
          >
            Don't have an account? Signup
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default SigninForm;
