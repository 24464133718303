// src/Navigation.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Pages


import Landing from '../pages/landing/Landing';
import About from './../pages/about/About'
import ContactForm from './../pages/contact/Contact'

import SigninForm from './../pages/auth/SigninForm';
import SignupForm from './../pages/auth/SignupForm';

import VerifyOtpForm from './../pages/auth/VerifyOtpForm';
import ForgotPasswordForm from './../pages/auth/ForgotPasswordForm';
import VerifyOtpPasswordResetForm from './../pages/auth/VerifyOtpPasswordResetForm';
import ResetPasswordForm from './../pages/auth/ResetPasswordForm';

import ProductAll from '../pages/products/AllProducts';
import ProductDetail from '../pages/products/ProductDetail';
import CartPage from './../pages/cart/ViewCart'
import CheckoutForm from '../pages/checkout/ChekoutForm';
import ConfirmationPage from '../pages/checkout/Confirmation';
import ProductListByAge from '../components/filters/AgeBased';
import Wishlist from '../pages/wishlist/WishList';
import Profile from '../pages/user/Profile';
import OrderHistory from './../pages/user/OrderHistroy'
import SearchResults from '../layouts/SearchResults';
import PrivacyPolicy from '../pages/footer/Privacy';
import TermsConditions from '../pages/footer/Term'

const Navigation = () => (

  <Routes>
    {/* Pages */}
    <Route path="/" element={<Landing />} />
    <Route path='/about' element={<About />} />
    <Route path='/contact' element={<ContactForm />} />
    <Route path="/search" element={<SearchResults />} />

    {/* Product */}
    <Route path="/product" element={<ProductAll />} />
    <Route path="/viewcart" element={<CartPage />} />
    <Route path='/checkout' element={<CheckoutForm />} />
    <Route path="/confirmation/order/:checkoutId" element={<ConfirmationPage />} />
    <Route path="/products/viewproduct/:productId" element={<ProductDetail />} />
    <Route path="/product/by-age/:ageGroup" element={<ProductListByAge />} />
    <Route path='/wishlist' element={<Wishlist />} />


    {/* Authentication */}
    <Route path="/signup" element={<SignupForm />} />
    <Route path="/verify-otp" element={<VerifyOtpForm />} />
    <Route path="/signin" element={<SigninForm />} />
    <Route path="/forgot-password" element={<ForgotPasswordForm />} />
    <Route path="/verify-otp-password-reset" element={<VerifyOtpPasswordResetForm />} />
    <Route path="/reset-password" element={<ResetPasswordForm />} />

    <Route path="/profile" element={<Profile />} />
    <Route path='/order-history' element={<OrderHistory />} />

    {/* Parent */}

    {/* <Route path="/parent-hood" element={<ParentReview />} />
      <Route path="/suggest-products-for-you" element={<ResultsPage />} /> */}

    {/* footer */}
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/term-and-condition" element={<TermsConditions />} />





  </Routes>

);

export default Navigation;
